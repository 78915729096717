import React, {
    useContext, useCallback, useState, useEffect, useMemo
} from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { ViewModelServiceContext, ViewModelForm , useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { Accordion } from '@jutro/legacy/components';
import { WMICCreditConsentUtil, WMICLogger, WMICRPCUtil, WMICVariousUtil, JURISDICTIONS, FlowStepId, WMICValidationUtil } from 'wmic-pe-portals-utils-js';
import { useWizardModals, useAccordionValidation, WMICWizardSubmissionPage } from 'wmic-pe-portals-wizard-components-ui';
import { WMICRequiredText } from 'wmic-pe-components-platform-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WMICPALossHistoryComponent, WMICPACreditConsentComponent } from 'wmic-pe-capability-gateway-quoteandbind-pa-react';
import { WMICBrokerInfo, messages as commonMessages } from 'wmic-pe-capability-gateway-quoteandbind-common-react';
import { WMICInsuranceHistoryPoliciesComponent } from 'wmic-pe-capability-gateway-common-react';
import { WMICPAValidationWarningUtil } from 'wmic-pe-capability-gateway-common-pa-react';
import { UISupportingInfoLookupService } from 'wmic-pe-capability-supportinginfo';
import metadata from './WMICPAInsuranceHistoryPage.metadata.json5';
import messages from './WMICPAInsuranceHistoryPage.messages';

const INSURANCE_HISTORY_PATH = 'lobData.personalAuto.insuranceHistory_WMIC';
const INSURANCE_HISTORY_VALUE_PATH = 'lobData.personalAuto.insuranceHistory_WMIC.value';
const BROKER_INFO_PATH = 'lobData.personalAuto.insuranceHistory_WMIC.brokerInfo';

function WMICPAInsuranceHistoryPage(props) {
    const { wizardData: submissionVM, updateWizardData } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useContext(TranslatorContext);
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const [loading, setLoading] = useState(true);
    const { authHeader, authUserData } = useAuthentication();
    const { refreshData } = useDataRefresh();
    const [insuranceHistoryPageDetails, setInsuranceHistoryPageDetails] = useState();
    const [insuranceHistoryVM, setInsuranceHistoryVM] = useState();
    const [showErrors, setShowErrors] = useState(false);
    const [carriersList, setCarrierList] = useState();
    const [isCreditScorevalidated, setIsCreditScorevalidated] = useState(false);
    const baseData = _.get(submissionVM, 'baseData');
    const baseStateCode = _.get(submissionVM, 'baseData.baseState.value.code');
    const rateAsOfDate = _.get(submissionVM, 'baseData.rateAsOfDate.value');
    const vehicles = _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.value');
    const pniVm = _.get(submissionVM, 'baseData.primaryNamedInsured_WMIC');
    const anis = _.get(submissionVM, 'baseData.additionalNamedInsureds_WMIC.value');
    const anisVM = _.get(submissionVM, 'baseData.additionalNamedInsureds_WMIC');
    const lob = _.get(submissionVM, 'lob.value.code');

    const { setWizardLoading, showCustom } = useWizardModals();

    const {
        onValidate,
        initialValidation,
        isComponentValid,
        registerInitialComponentValidation
    } = useValidation('WMICPAInsuranceHistory');

    const validateInitialTableForm = useCallback(() =>
        !WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.PA_INSURANCE_HISTORY)
    , [submissionVM]);

    useEffect(() => {
        registerInitialComponentValidation(validateInitialTableForm);
    }, [registerInitialComponentValidation, validateInitialTableForm]);

    const { onValidateAccordion, isAccordionValid } = useAccordionValidation(onValidate);

    const updateInsuranceHistoryVM = (historyVM) => {
        refreshData();
        setInsuranceHistoryVM(historyVM);

        const currentInsuranceHistory = _.get(submissionVM, INSURANCE_HISTORY_VALUE_PATH);

        if (!_.isEqual(currentInsuranceHistory, historyVM.value)) {
            _.set(
                submissionVM,
                INSURANCE_HISTORY_VALUE_PATH,
                historyVM.value
            );
            updateWizardData(submissionVM);
        }
    }

    const createPolicyInfoVM = () => viewModelService.create(
        { otherLicensedToDrive: {} },
        'pc',
        'wmic.edge.ca.capabilities.policyjob.lob.common.draft.dto.insurancehistory.PriorPolicyInfo_WMICDTO',
        submissionVM.aspects.context()
    );

    const createBrokerInfo = () => {
        const brokerInfo  = viewModelService.create(
            {},
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.common.draft.dto.insurancehistory.BrokerInfo_WMICDTO'
        );

        brokerInfo.value.paBrokerInfo = viewModelService.create(
            {},
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.common.draft.dto.insurancehistory.PaBrokerInfo_WMICDTO'
        ).value;

        return brokerInfo;
    };

    const initInsuranceHistory = () => {
        const insuranceHistoryTmp = _.get(submissionVM, INSURANCE_HISTORY_PATH);
        const priorPolicyInfo = _.get(insuranceHistoryTmp, 'priorPolicyInfo');

        _.set(insuranceHistoryTmp, 'isAddingPriorLoss', false);
        _.set(insuranceHistoryTmp, 'isAddingOtherInsuranceCoPolicyRecord', false);
        _.set(insuranceHistoryTmp, 'isAddingOtherWawanesaPolicyRecord', false);
        _.set(insuranceHistoryTmp, 'isAddingDeclinedPolicyRecord', false);

        // creating VMs for priorPolicyInfo, priorLosses, brokerInfo will be below
        if (!priorPolicyInfo?.value) {
            const policyInfoVM = createPolicyInfoVM();

            _.set(insuranceHistoryTmp, 'priorPolicyInfo', policyInfoVM.value);
        }

        // Create Prior Losses Array
        if (!insuranceHistoryTmp.priorLosses.value) {
            _.set(insuranceHistoryTmp, 'priorLosses', []);
        }

        let brokerInfo = _.get(submissionVM, BROKER_INFO_PATH);

        if (!brokerInfo.value) {
            brokerInfo = createBrokerInfo();

            _.set(insuranceHistoryTmp, 'brokerInfo', brokerInfo.value)
        }

        _.set(submissionVM, INSURANCE_HISTORY_PATH, insuranceHistoryTmp);
        setInsuranceHistoryPageDetails(insuranceHistoryTmp);
        setLoading(false);

        updateInsuranceHistoryVM(insuranceHistoryTmp);
    }

    useEffect(() => {
        initInsuranceHistory();
        // Keeping this lookup here to reduce network payload size for performance
        UISupportingInfoLookupService.retrieveInsuranceCarriersList([baseData.baseState.value.code, baseData.periodStartDate.value], true, authHeader)
            .then((carriers) => {
                const updatedCarriers = carriers.map((item) => ({
                    ...item,
                    name: item.carrier,
                    code: item.carrier,
                }))

                setCarrierList(updatedCarriers);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        submissionVM.value.flowStepId_WMIC = FlowStepId.PA_INSURANCE_HISTORY;
    }, [submissionVM.value]);

    const updateDrivers = useCallback((namedInsured) => {
        const allDrivers = _.get(submissionVM, 'lobData.personalAuto.coverables.drivers.value');
        if (WMICCreditConsentUtil.isAniCreditConsentable(namedInsured) && allDrivers && allDrivers.length > 0) {
            let found = false;
            const ani = namedInsured.value;
            for (let driverCounter = 0; !found && driverCounter < allDrivers.length; driverCounter++) {
                const driver = allDrivers[driverCounter];
                if (driver.person.publicID === ani.contactPublicID || driver.person.publicID === ani.publicID) {
                    driver.dateOfBirth = _.get(ani, 'dateOfBirth');
                    driver.creditConsentReceived = _.get(ani, 'creditConsentReceived');
                    driver.creditConsentDate = _.get(ani, 'creditConsentDate');
                    driver.creditInfoWithdrawalConsent = _.get(ani, 'creditInfoWithdrawalConsent');
                    driver.personalInfoConsentForm = _.get(ani, 'personalInfoConsentForm');
                    found = true;
                }
            }
            updateWizardData(submissionVM);
        }
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submissionVM]);

    const onSaveInsuranceHistory = useCallback(async () => {

        if (!isComponentValid) {
            setShowErrors(true);
            return false;
        }

        try {
            setShowErrors(false);
            setWizardLoading(true, translator(messages.savingInsuranceHistory));

            if (baseStateCode === JURISDICTIONS.QUEBEC) {
                anisVM.forEach((ani) => updateDrivers(ani));
            }

            submissionVM.value = await LoadSaveService.saveAndValidate_WMIC(
                submissionVM.value,
                authHeader
            );

            initInsuranceHistory();
            updateWizardData(submissionVM);

            if (WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.PA_INSURANCE_HISTORY)) {
                WMICVariousUtil.scrollToTop();
            }
        } catch (err) {
            WMICLogger.error('WMICPAInsuranceHistoryPage onSaveInsuranceHistory ', err);
        } finally {
            setWizardLoading(false);
        }

    }, [submissionVM, translator, isComponentValid, updateDrivers, baseStateCode]);

    const onNext = useCallback(async () => {
        try {
            if (!isComponentValid) {
                setShowErrors(true);
                return false;
            }

            setWizardLoading(true);

            if (baseStateCode === JURISDICTIONS.QUEBEC) {
                anisVM.forEach((ani) => updateDrivers(ani));
            }

            const newSubmissionVM = viewModelService.clone(submissionVM);

            _.unset(newSubmissionVM.value, 'bindData');

            let dtoValidationInvalid = false;
            submissionVM.value = await LoadSaveService.updateDraftSubmissionWithLOBData(newSubmissionVM.value, authHeader);

            if (WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.PA_INSURANCE_HISTORY)) {
                WMICVariousUtil.scrollToTop();
                dtoValidationInvalid = true;
            }

            const isInvalid = !isCreditScorevalidated && WMICPAValidationWarningUtil.checkPACreditScoreValidation(submissionVM, translator, showCustom);
            setIsCreditScorevalidated(true);

            return isInvalid || dtoValidationInvalid ? false : submissionVM;
        } finally {
            setWizardLoading(false);
        }
    }, [isComponentValid, setWizardLoading, baseStateCode, viewModelService, submissionVM, LoadSaveService, authHeader, isCreditScorevalidated, translator, showCustom, anisVM, updateDrivers]);

    const isCreditConsentVisible = useMemo(() => {
        const hasOnlyIRCAVehicles = WMICCreditConsentUtil.hasOnlyIRCAVehicles(vehicles)
        const discountCreditConsentRPCIsEffective = WMICRPCUtil.getIsRPCEffective(
            baseStateCode,
            rateAsOfDate,
            '1503', '1504', '1576', '1543'
        );
        const hasPersonalVehicleOrMotorHome = WMICCreditConsentUtil.hasPersonalVehicleOrMotorHome(vehicles);
        const isPniCreditConsentable = WMICCreditConsentUtil.isPniCreditConsentable(pniVm);
        const isAnyAniCreditConsentable = WMICCreditConsentUtil.isAnyAniCreditConsentable(anis);

        return ( 
            (!hasOnlyIRCAVehicles && discountCreditConsentRPCIsEffective && hasPersonalVehicleOrMotorHome && (isPniCreditConsentable || isAnyAniCreditConsentable)) 
            || 
            (isAnyAniCreditConsentable && baseStateCode === JURISDICTIONS.QUEBEC)
        );

    }, [baseStateCode, rateAsOfDate, vehicles, pniVm, anis]);

    const commonAccordionProps = {
        showErrors,
    }

    // props for the accordion components
    const commonAccordionContentProps = {
        showErrors,
        isReadOnlyUser: false,
        // todo isEditMode: personalAuto.insuranceHistory_WMIC.isUnderEditing
        isEditMode: true,
        onValidate: onValidateAccordion,
        submissionVM,
        updateWizardData,
        authHeader,
        baseData,
        updateHistory: updateInsuranceHistoryVM,
        insuranceHistoryVM
    };

    const overrideProps = {
        insuranceHistoryContainerButtons: {
            onSave: onSaveInsuranceHistory,
            saveLabel: translator(messages.saveInsuranceHistory)
        },
        policiesAccordion: {
            ...commonAccordionProps,
            isValid: isAccordionValid('policiesAccordionContent')
        },
        policiesAccordionContent: {
            ...commonAccordionContentProps,
            carriersList,
            jobVM: submissionVM
        },
        lossHistoryAccordion: {
            ...commonAccordionProps,
            isValid: isAccordionValid('lossHistoryAccordionComponent')
        },
        lossHistoryAccordionComponent: {
            ...commonAccordionContentProps
        },
        brokerAccordion: {
            ...commonAccordionProps,
            isValid: isAccordionValid('brokerAccordionContent')
        },
        brokerAccordionContent: {
            ...commonAccordionContentProps,
            updateBrokerInfo: refreshData,
            brokerInfo: _.get(insuranceHistoryPageDetails, 'brokerInfo'),
            authUserData,
            lob
        },
        creditConsentAccordion: {
            ...commonAccordionProps,
            visible: isCreditConsentVisible,
            isValid: isAccordionValid('creditConsentAccordionComponent')
        },
        creditConsentAccordionComponent: {
            ...commonAccordionContentProps,
        }
    };

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap:{
            WMICRequiredText,
            WMICBrokerInfo,
            WMICPACreditConsentComponent,
            WMICPALossHistoryComponent,
            WMICInsuranceHistoryPoliciesComponent,
            Accordion
        }
    };

    if (loading) {
        return null;
    }

    return (
        <WMICWizardSubmissionPage
            onNext={onNext}
            skipWhen={initialValidation}
            cancelLabel={translator(commonMessages.saveAndExit)}
            showRequired
            flowStepId={FlowStepId.PA_INSURANCE_HISTORY}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                onModelChange={updateWizardData}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WMICWizardSubmissionPage>);
}

WMICPAInsuranceHistoryPage.propTypes = wizardProps;

export default WMICPAInsuranceHistoryPage;
