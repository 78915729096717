import { defineMessages } from 'react-intl';

export default defineMessages({
    bind: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.QuotePage.Bind',
        defaultMessage: 'Bind'
    },
    bindpolicychangebutton: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.QuotePage.BindPolicyChangeButton',
        defaultMessage: 'Bind Policy Change'
    },
    binding: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.QuotePage.Binding policy change...',
        defaultMessage: 'Binding policy change...'
    },
    loading: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.QuotePage.Loading quote...',
        defaultMessage: 'Loading quote...'
    },
    bindpolicy: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.QuotePage.BindPolicyChange',
        defaultMessage: 'Bind Policy Change'
    },
    bindmessage: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.QuotePage.PolicyChangeBound',
        defaultMessage: 'Policy Change will be bound, would you like to continue?'
    },
    bindpolicytitle: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.QuotePage.BindPolicyChangeTitle',
        defaultMessage: 'Bind Policy Change'
    },
    cancelbutton: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.QuotePage.cancel',
        defaultMessage: 'Cancel'
    },
    warningRenewalExists: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.QuotePage.WarningRenewalExists',
        defaultMessage: 'Warning - Renewal Exists'
    },
    renewalIssuedForPolicy: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.QuotePage.RenewalIssuedForPolicy',
        defaultMessage: 'A renewal has been issued for this policy'
    },
    proceedWithRenewalChanges: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.QuotePage.Proceed with Renewal Changes',
        defaultMessage: 'Proceed with Renewal Changes'
    },
    changesCopiedToRenewal: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.QuotePage.Changes copied to renewing term',
        defaultMessage: 'Policy changes have been completed on current term and copied to the renewing term. Proceed to renewal transaction to review and bind.'
    },
    proceed: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.QuotePage.Proceed',
        defaultMessage: 'Proceed'
    },
    applyingPolicyChange: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.QuotePage.Applying Policy Change',
        defaultMessage: 'Applying Policy Change'
    },
    error: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.QuotePage.Error',
        defaultMessage: 'Error'
    },
    bindingFailure: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.QuotePage.Binding Failure',
        defaultMessage: 'Cannot bind the policy.'
    },
});
